.limiter {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
}

.containerLogin100 {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.wrapLogin100 {
  max-width: 390px;
  //background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 10px 10px;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 51px;
}

.loginForm {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.wrapInput100 {
  width: 100%;
  display: flex;
  background-color: #e6e6e6;
  border: 1px solid transparent;
  border-radius: 3px;
  margin-bottom: 16px;
}

.inputW {
  color: #403866;
  line-height: 1.2;
  font-size: 18px;
  display: block;
  width: 100%;
  background: 0 0;
  height: 50px;
  padding: 0 20px 0 38px;
}

.containerFormLoginBtn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 17px;
}

.login100Btn {
  font-size: 16px;
  color: #b3d4fc;
  line-height: 1.2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  background-color: #53b5e9;
  border-radius: 3px;
  transition: all 0.4s;
  margin-top: 17px;
  font-weight: bold;
}

.footer {
  padding: 20px;
  align-self: flex-end;
}

button {
  outline: none !important;
  border: none;
  background: 0 0;
}
input {
  outline: none;
  border: none;
}
