$dashborad-bg-color: #8ea6b3; // #0e0e0f;
$box-color: #1973bb;
$menu-color: #0c1331;

body {
  // background-color: $dashborad-bg-color;
}
.layout {
  flex: 1;
}
a {
  text-decoration: none;
}
